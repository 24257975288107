import Layout from "./components/Layout";
import { Styles } from "./GlobalStyles";


function App() {
  return (
    <>
      <Layout />
      <Styles />
    </>
  );
}

export default App;
