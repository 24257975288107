import React from 'react';

import { Container } from './styles';

function Header() {
  return(
    <Container>
      <a href="https://www.console.ellacarautos.com.br/">Carros adicionados</a>

      <h2>Adicionar carros:</h2>
    </Container>
  );
}

export default Header;