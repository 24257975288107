import styled from "styled-components";

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`

export const Wrapper = styled.div`

  margin: 0 auto;
  padding: 0 auto;

  max-width: 700px;
  height: auto;

`
export const Title = styled.h2`

  font-size: 25px;
  font-weight: bold;

  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

`
export const Essentials = styled.div`

width: 100%;
margin-bottom: 15px;

.title-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #02099466;
  border-radius: 5px;

  width: 100%;
  height: 50px;

  margin-bottom: 15px;
}
.input-All{
  padding: 5px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;

  background-color: #02099466;
  border-radius: 5px;

  padding: 10px;

  .input-wrapper{

display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

  h2{
    font-size: 20px;
  }
  input{
    height: 30px;
    width: 200px;
    border: none;
    padding: 5px;
    color: black;
    font-size: 18px;
    margin-bottom: 10px;
  }
  }
}

`
export const Extras = styled.div`

width: 100%;
margin-bottom: 15px;

.title-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #02099466;
  border-radius: 5px;

  width: 100%;
  height: 50px;

  margin-bottom: 15px;
}
.opt-all{
  padding: 5px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;

  background-color: #02099466;
  border-radius: 5px;

  .opt-wrapper{
    display: flex;
    align-items: center;
    margin: 10px;

  h3{
    margin-right: 5px;
  }
  }

}

`
export const Licence = styled.div`
  width: 100%;
  margin-bottom: 15px;

.title-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #02099466;
  border-radius: 5px;

  width: 100%;
  height: 50px;

  margin-bottom: 15px;
}
.opt-all{
  padding: 5px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-around;

  background-color: #02099466;
  border-radius: 5px;

  .opt-wrapper{
    display: flex;
    align-items: center;
    margin: 10px;

  h3{
    margin-right: 5px;
  }
  }

}
`
export const Image = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;

  .title-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #02099466;
    border-radius: 5px;

    width: 100%;
    height: 50px;

    margin-bottom: 15px;
  }
  .image-wrapper{
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #02099466;
    border-radius: 5px;
    margin-bottom: 15px;


    iframe{
      width: 230px;
      height: 150px;
      border: 5px solid white;
      border-radius: 10px;
    }

    img{
      width: 260px;
      height: 170px;
      border: 5px solid red;
      border-radius: 10px;
    }

    .input-i360{
      display: flex;
      flex-direction: column;
      input{
        width: 250px;
        font-size: 18px;
        padding: 10px;
        border-radius: 10px;
        border: none;
        background-color: white;
      }
      button{
        width: 150px;
        height: 30px;
        margin-top: 5px;
        align-self: center;
        border: none;
        background-color: white;
        border-radius: 5px;
        cursor: pointer;

        :hover{
          opacity: 0.5;
        }
      }
    }
  }

  .addingImages{
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    div.sendImage{
      margin-bottom: 15px;
      a{
        background: #02099466;
        text-decoration: none;
        color: white;
        border: 2px solid white;
        margin: 10px;
        padding: 10px;

      }
        a:hover{
          opacity: 0.7;
        }
    }

    div.imageAdd_area{
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; 
      margin: 15px;

      img{
        width: 250px;
        height: 150px;
        background: transparent;
        border: 3px dashed white;
        border-radius: 10px;
      }

      p{
        margin-right: 15px;
        margin-left: -5px;
      }

      input{
        margin: 10px;
        padding: 5px;
        border: none;
        outline: none;
        background-color: whitesmoke;
        color: black;
        align-self: center;
        justify-self: center;
        border-radius: 5px;
        max-width:300px;
      }
      .check-banner{
        margin-left: -5px;
      }
    }

    .image-list{
      display: flex;
      flex-direction: row;

      li{
        list-style: none;
        border: 2px solid gray;

        img{
          width: 100px;
        }
      }
    }

    .imgAdd_buttons{

      display: flex;

      button{
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 10px;

      width: 300px;
      height: 40px;
      padding: 0 15px;

      font-size: 18px;
      background-color: #02099466;
      outline: none;
      border: 2px dashed white;
      color: white;
      cursor: pointer;
      
      :hover{
        opacity: 1;
        background-color: #020999;
      }

    }
    }


    ul{
      display: flex;
      justify-content: center;
      max-width: 700px;
      flex-flow: row wrap;

      .banner-view_wrapper{
        border: 2px solid red;

        li{
        list-style: none;
        .addedImage{
          width: 250px;
          height: 100px;
          padding: 5px;
          margin-bottom: 5px;
        }
      }
    }
    li{
      list-style: none;
      display: flex;
      .addedImage{
        max-width: 150px;
        max-height: 90px;
        padding: 5px;
        margin-bottom: 5px;

        :nth-child(n + 1){
          border-right: 2px solid gray ;
        }
      }
    }
  }
`
export const Hist = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  align-self: center;
  flex-direction: column;

  .title-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #02099466;
    border-radius: 5px;

    width: 100%;
    height: 50px;

    margin-bottom: 15px;
  }

  textarea{
    align-self: center;
    width: 700px;
    height: 300px;
    color: black;
    border: none;
    border-radius: 15px;
    padding: 15px;
    font-size: 18px;
    outline: none;
  }
`
export const Btn = styled.button`
  border-radius: 15px;
  border: none;

  background-color: #8a2be2;
  color: white;
  width: 100%;
  height: 5%;

  font-size: 28px;
  font-weight: bold;

  margin-bottom: 20px;
  cursor: pointer;

  :hover{
    opacity: 1;
    background-color: #8a2be233;
  }
`
export const ImageAdd = styled.div`

  display: flex;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  height: 100%;

  input[type="file"]{
    display: none;
  }

  .fileChooser{
    background: whitesmoke;
    display: flex;

    align-items: center;
    justify-content: space-around;

    width: max-content;
    height: 40px;
    padding: 5px;
    border-radius: 5px;
    color: black;
    cursor: pointer;

    transition: 0.3s;

    :hover{
      opacity: 0.9;
    }

    p{
      margin: 0 5px;
    }
  }

  img{
    width: 300px;
    height: 180px;
    border: 4px dashed white;
    border-radius: 5px;
    background: transparent;
  }


`