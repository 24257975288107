import React from 'react';
import Header from '../../components/Header'
import Home from '../../Home';

import { Container } from './styles';

function Layout() {
  return (
    <Container>
      <Header/>
      <Home/>
    </Container>
  )
}

export default Layout;