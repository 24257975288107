import React, { useState } from 'react';
import {db} from '../server'

import {
  Container,
  Title,
  Wrapper,
  Essentials,
  Extras,
  Licence,
  Image,
  Hist,
  Btn,
  // ImageAdd
} from './styles';
// import Popup from 'reactjs-popup';

function Home() {

  const [selectImage, setSelectImage] = useState('')
  const [images, setImages] = useState([])
  const [banner, setBanner] = useState('')
  
  const [imageID, setImageID] = useState('')
  const imageUrl = []

  const [brand, setBrand] = useState('')
  const [model, setModel] = useState('')
  const [year, setYear] = useState('')
  const [price, setPrice] = useState('')
  const [engine, setEngine] = useState('')
  const [km, setKm] = useState('')

  const [alarm, setAlarm] = useState('')
  const [cbAuto, setCbAuto] = useState('')
  const [multi, setMulti] = useState('')
  const [dh, setDh] = useState('')
  const [AC, setAC] = useState('')
  const [eletricLock, setEletricLock] = useState('')
  const [bc, setBc] = useState('')
  const [farolMi, setFarolMi] = useState('')
  const [senseRe, setSenseRe] = useState('')
  const [camRe, setCamRe] = useState('')

  const [ipva, setIpva] = useState('')
  const [swt, setSwt] = useState('')
  const [license, setLicense] = useState('')
  const [financ, setFinanc] = useState('')

  const [hist, setHist] = useState(``)
  const [i360, setI360] = useState('')
  const [disp, setDisp] = useState(true)

  async function addImage(){

    setImageID(Math.floor(Date.now() * Math.random()).toString(32))

      images.push({
        src: selectImage,
        id: Math.floor(Date.now() * Math.random()).toString(32),
      })

    setSelectImage('')
    setImageID('')

    console.log(images)

  }

  async function add(){

    if(alarm !== true){
      setAlarm(false)
    }
     else if(cbAuto !== true){
      setCbAuto(false)
    }
     else if(multi !== true){
    }
    else if(dh !== true){
      setDh(false)
    }
    else if(disp === false){
      setDisp(true)
    }

    const carInfo = db.collection('carros')

    await carInfo.add({
      brand: brand,
      model: model,
      year: year,
      price: `R$${price}`,
      engine: engine,
      km: km,
      alarm: alarm,
      cbAuto: cbAuto,
      multi: multi,
      dh: dh,
      AC: AC,
      eletricLock: eletricLock,
      bc: bc,
      farolMi: farolMi,
      senseRe: senseRe,
      camRe: camRe,
      ipva: ipva,
      swt: swt,
      license: license,
      financ: financ,
      hist: hist,
      i360: i360,
      disp: disp,
      images: images,
      banner: banner
    }).then(()=>{
      console.log('adicionado com sucesso')
      alert('adicionado com sucesso')
    })
    .catch((error)=>{
      console.log(error)
      alert('ocorreu algum problema! /r/n' + error)
    })

  }

  return (

    <>
      <Container>

        <Wrapper>

        <Essentials>
          <div className='title-wrapper'>
            <Title>Essenciais:</Title>
          </div>

          <div className='input-All'>

              <div className='input-wrapper'> 
                <h2>Marca</h2>
                <input type="text" value={brand} placeholder='ex: Honda' onChange={(e) => setBrand(e.target.value)}/>
              </div>

              <div className='input-wrapper'>
                <h2>Modelo</h2>
                <input type="text" value={model} placeholder='ex: Civic' onChange={(e) => setModel(e.target.value)}/>
              </div>

              <div className='input-wrapper'>
                <h2>Ano do carro</h2>
                <input type="text" value={year} placeholder='ex: 2018' onChange={(e) => setYear(e.target.value)}/>
              </div>
              

              <div className='input-wrapper'>
                <h2>Preço</h2>
                <input type="text" value={price} placeholder='ex: 15,000' onChange={(e) => setPrice(e.target.value)}/>
              </div>


              <div className='input-wrapper'>
                <h2>km/s rodados</h2>
                <input type="text" value={km} placeholder='(ex:200,000)' onChange={(e) => setKm(e.target.value)}/>
              </div>

              <div className='input-wrapper'>
                <h2>Motor</h2>
                <input type="number" value={engine} placeholder='ex: 1.0' onChange={(e) => setEngine(e.target.value)}/>
              </div>

          </div>
        </Essentials>

        <Extras>
          <div className='title-wrapper'>
            <Title>Extras:</Title>
          </div>

          <div className='opt-all'>

            <div className='opt-wrapper'>
              <h3>Alarme</h3>
              <input type='checkbox' value={alarm} onChange={() => setAlarm(!alarm)}/>
            </div>

            <div className='opt-wrapper'>
              <h3>Cambio altomatico</h3>
              <input type='checkbox' value={cbAuto} onChange={() => setCbAuto(!cbAuto)}/> 
            </div>


            <div className='opt-wrapper'>
              <h3>Multimidia</h3>
              <input type='checkbox' value={multi} onChange={() => setMulti(!multi)}/> 
            </div>

            <div className='opt-wrapper'>
              <h3>Direção hidraulica</h3>
              <input type='checkbox' value={dh} onChange={() => setDh(!dh)}/> 
            </div>

            <div className='opt-wrapper'>
              <h3>Ar condiconado</h3>
              <input type='checkbox' value={AC} onChange={() => setAC(!AC)}/> 
            </div>

            <div className='opt-wrapper'>
              <h3>Trava elétrica</h3>
              <input type='checkbox' value={eletricLock} onChange={() => setEletricLock(!eletricLock)}/> 
            </div>

            <div className='opt-wrapper'>
              <h3>Bancos de couro</h3>
              <input type='checkbox' value={bc} onChange={() => setBc(!bc)}/>
            </div>

            <div className='opt-wrapper'>
              <h3>Farol de milha</h3>
              <input type='checkbox' value={farolMi} onChange={() => setFarolMi(!farolMi)}/>
            </div>

            <div className='opt-wrapper'>
              <h3>Sensor de ré</h3>
              <input type='checkbox' value={senseRe} onChange={() => setSenseRe(!senseRe)}/>
            </div>

            <div className='opt-wrapper'>
              <h3>Camera de ré</h3>
              <input type='checkbox' value={camRe} onChange={() => setCamRe(!camRe)}/>
            </div>

          </div>

        </Extras>

        <Licence>
          <div className='title-wrapper'>
            <Title>Burocracias:</Title>
          </div>

          <div className='opt-all'>

            <div className='opt-wrapper'>
              <h3>Licenciado</h3>
              <input type='checkbox' value={license} onChange={() => setLicense(!Licence)}/>
            </div>

            <div className='opt-wrapper'>
              <h3>IPVA pago</h3>
              <input type='checkbox' value={ipva} onChange={() => setIpva(!ipva)}/>
            </div>

            <div className='opt-wrapper'>
              <h3>Aceita troca</h3>
              <input type='checkbox' value={swt} onChange={() => setSwt(!swt)}/>
            </div>

            <div className='opt-wrapper'>
              <h3>Aceita financiado</h3>
              <input type='checkbox' value={financ} onChange={() => setFinanc(!financ)}/>
            </div>

          </div>
        </Licence>

        <Image>
          <div className='title-wrapper'>
            <Title>Imagens:</Title>
          </div>

          <div className='image-wrapper'>
            <div className='input-i360'>
              <h3>Banner</h3>
              <input type="text" value={banner} placeholder='URL do banner' onChange={(e)=> setBanner(e.target.value)}/>
              <button onClick={()=>{setBanner('')}}>Limpar campo</button>
            </div>

            <img src={banner} alt="banner" />
          </div>

          <div className='image-wrapper'>
            <div className='input-i360'>
              <h3>Modelo 360</h3>
              <input type="text" value={i360} placeholder='[link da imagem 360]' onChange={(e)=> setI360(e.target.value)}/>
              <button onClick={()=>{setI360('')}}>Limpar campo</button>
            </div>

            <iframe src={i360} title='model_360'/>
          </div>

          <div className='addingImages'>

            <div className="sendImage">
              <a href="https://imageaddgoodcar.netlify.app/" target='_blank'>Enviar imagens e pegar URL</a>
            </div>

            <div className='imageAdd_area'>
              <div>
                <input type="text" value={selectImage} className='image-add' placeholder='url da imagem' onChange={(e) => setSelectImage(e.target.value)}/>
              </div>

              <img src={selectImage} alt="imagem selecionada" />
            </div>

            <div className='imgAdd_buttons'>
              <button onClick={addImage}>adicionar imagem</button>
            </div>

            <ul className='image-list'>
            {
              images.map((i)=>{
                return(
                  <li key={i.id}>
                    <img src={i.src} alt="imageContent" />
                </li>
                )
              })
            }
            </ul>

          </div>

        </Image>

        <Hist>
          <div className='title-wrapper'>
            <Title>Histórico do veículo</Title>
          </div>
          <textarea placeholder='histórico...' value={hist} onChange={(e)=> setHist(e.target.value)}/>
        </Hist>

        <Btn onClick={add}>Adicionar veículo</Btn>
        </Wrapper>
      </Container>
    </>
  );
}

export default Home;
