import { createGlobalStyle } from "styled-components";

export const Styles = createGlobalStyle`

*{
  margin: 0;
  padding: 0;

  box-sizing: border-box;
}
html{
  color: white;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  background-color: #020833;
}
button{
  transition: 0.3s;
  :hover{
    opacity: 0.8;
  }
}
`