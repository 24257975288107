import styled from "styled-components";

export const Container = styled.div`

margin: 0;
padding: 0;

display: flex;
  flex-direction: column;
  justify-content: center;
align-items: center;

`