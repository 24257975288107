import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

let firebaseConfig = {
  apiKey: "AIzaSyDyjCFfDAmANDxJ0H_W6pwD5dNjYQqjCcg",
  authDomain: "goodca-database.firebaseapp.com",
  projectId: "goodca-database",
  storageBucket: "goodca-database.appspot.com",
  messagingSenderId: "1081632072091",
  appId: "1:1081632072091:web:8e8380b9bfd43495bd813e",
};

firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore()
export const storage = firebase.storage()